import React, { useState, useEffect } from "react";
import BaseApi from "../api/BaseApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assets/styles/register.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { isValidPhoneNumber } from "react-phone-number-input/input";
import PhoneInput from "react-phone-number-input/input";
import bidLogoPurple from "../assets/images/BiD-LOQO-purple.svg";
import profilpic from "../assets/images/profile-pic.jpg";

import { options } from "../constants";
import { useSelector } from "react-redux";

export default function SimpleSlider() {
  const language = useSelector(state => state.language)
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // arrows:false,
  };


  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState(new Date());
  const [male, setMale] = useState(false);
  const [female, setFemale] = useState(false);
  const [phone, setPhone] = useState();
  const [isValidNumber, setIsValidNumber] = useState(true);
  const [isValidName, setIsValidName] = useState(true);
  const [isValidSurname, setIsValidSurname] = useState(true);
  const [isValidDatetime, setIsValidDatetime] = useState(true);
  const [isValidAge, setisValidAge] = useState(true);
  const [isValidGender, setIsValidGender] = useState(true);

  const [fileInput, setFileInput] = useState(profilpic);
  const [taxiCardImg, setTaxiCardImg] = useState(" ");
  const [taxiCertificateImg, setTaxiCertificateImg] = useState(" ");
  useEffect(() => {
    setIsValidNumber(true);
  }, [phone]);


  const validatePhoneNumber = (phone) => {
    if (phone !== undefined) {
      setIsValidNumber(isValidPhoneNumber(phone));
      if (isValidPhoneNumber(phone)) {
        return true;
      }
    } else {
      setIsValidNumber(false);
    }
  };

  const validationGender = (male, female) => {
    if (male || female) {
      setIsValidGender(true);
      return true;
    } else {
      setIsValidGender(false);
    }
  };
  const validationTaxiCardImg = (image) => {
    if (image !== " " && image !== "0" && image?.length !== 0) {
      return true;
    } else {
      setTaxiCardImg("");
      return false;
    }
  };
  const validationTaxiCertificateImg = (image) => {
    if (image !== " " && image !== "0" && image?.length !== 0) {
      return true;
    } else {
      setTaxiCertificateImg("");
      return false;
    }
  };

  const validationDatetime = (date) => {
    if (date !== "") {
      setIsValidDatetime(true);
      // if ((+date.substring(0, 4) > 2006) | (+date.substring(0, 4) < 1930)) {
      //   setisValidAge(false);
      // } else {
      setisValidAge(true);
      return true;
      // }
    } else {
      setIsValidDatetime(false);
      return false;
    }
  };
  const validateNameSurname = (name, surname) => {
    if ((name.length <= 2) | (name.length >= 20)) {
      setIsValidName(false);
      if ((surname.length <= 2) | (surname.length >= 20)) {
        setIsValidSurname(false);
      } else {
        setIsValidSurname(true);
        return true;
      }
    } else {
      setIsValidName(true);
      if ((surname.length <= 2) | (surname.length >= 20)) {
        setIsValidSurname(false);
      } else {
        setIsValidSurname(true);
        return true;
      }
    }
  };

  const request = async () => {
    const fd = new FormData();
    fd.append("name", name);
    fd.append("lastname", surname);
    fd.append("mobileNum", phone);
    fd.append("gender", male ? 0 : 1);
    fd.append("birthDate", date);
    fd.append("mail", email);
    fd.append("img", fileInput !== profilpic ? fileInput : null);
    fd.append("taxiCard", taxiCardImg);
    fd.append("taxiCertificate", taxiCertificateImg);

    const response = await BaseApi.post("/driver/register", fd);
    if (response.data.statusCode === 200) {
      // setTimeout(() => {
      //   setRegistered(true);
      // }, 2000);
      toast.success("Qeydiyyatınız uğurla nəticələndi", { autoClose: 5000 });
    } else {
      // const message = response.data.message.replace('Validation failed:')
      //  message = message.replace('')
      toast.error(response.data.message);
    }
  };

  const HandleChange = (e, num) => {
    switch (num) {
      case 1:
        setName(e.target.value);
        setIsValidName(true);
        break;
      case 2:
        setEmail(e.target.value);
        break;
      case 3:
        setIsValidNumber(true);
        break;
      case 4:
        setDate(e);
        setIsValidDatetime(true);
        setisValidAge(true);
        break;
      case 5:
        setSurname(e.target.value);
        setIsValidSurname(true);
        break;
      case 8:
        setMale(e.target.checked);
        setIsValidGender(true);
        break;
      case 9:
        setFemale(e.target.checked);
        setIsValidGender(true);
        break;
      default:
        break;
    }
  };

  const HandleSubmit = () => {
    let phoneNumberValid = validatePhoneNumber(phone);
    let nameSurnameValid = validateNameSurname(name, surname);
    let datetimeValid = validationDatetime(date);
    let genderValid = validationGender(male, female);
    let taxiCardImgValidate = validationTaxiCardImg(taxiCardImg);
    let taxiCertificateImgValidate =
      validationTaxiCertificateImg(taxiCertificateImg);
    if (
      phoneNumberValid &&
      datetimeValid &&
      nameSurnameValid &&
      genderValid &&
      taxiCardImgValidate &&
      taxiCertificateImgValidate
    ) {
      request();
    }
    //else {
    //   if (
    //     emailValid &&
    //     phoneNumberValid &&
    //     nameSurnameValid &&
    //     datetimeValid &&
    //     genderValid &&
    //     pinValid
    //   ) {
    //     request();
    //   }
    // }
  };

  return (
    <>
      <ToastContainer />

      <Slider {...settings}>
        <div className="register-right-container 1">
          <div className="register-right-top">
            <div className="register-right-text-container">
              <h1
                className="register-heading"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={bidLogoPurple}
                  alt="bid-logo"
                  className="bid-right-logo"
                />
                <span
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    marginLeft: "10px",
                    marginTop: "10px",
                    color: "#6F52EA",
                    fontWeight: "700",
                  }}
                >
                  {options[language].driver}
                </span>
              </h1>
              <p className="welcome">{options[language].welcome}</p>
            </div>
            <label htmlFor="file-input" className="profile-photo">
            </label>
          </div>
          <form
            className="form-register"
            onSubmit={(e) => {
              e.preventDefault();
              HandleSubmit();
            }}
          >
            <div className="form-register-inputs">
              <div className="register-field-two-inputs">
                <div className="register-field name">
                  <label htmlFor="name">{options[language].nameReq}</label>
                  <input
                    placeholder="Ad"
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => HandleChange(e, 1)}
                  />
                  {!isValidName ? (
                    <div className="error-message">
                     {options[language].nameLimits}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="register-field surname">
                  <label htmlFor="surname">{options[language].surnameReq}</label>
                  <input
                    placeholder="Soyad"
                    type="text"
                    id="surname"
                    value={surname}
                    onChange={(e) => HandleChange(e, 5)}
                  />
                  {!isValidSurname ? (
                    <div className="error-message">
                      {options[language].surnameLimits}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className="register-field datetime">
                <label htmlFor="datetime">{options[language].dataReq2}</label>
                {/* <input
                  type="date"
                  id="datetime"
                  value={date}
                  placeholder="dd-mm-yyyy"
                  onChange={(e) => HandleChange(e.target.value, 4)}
                  min="2017-12-31"
                  max={new Date()}
                /> */}
                <DatePicker
                  onChange={(e) => HandleChange(e, 4)}
                  value={date}
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  // locale="az"
                  format="d MMMM yyyy"
                />
              </div>
              {!isValidDatetime ? (
                <div className="error-message">{options[language].dataReq}</div>
              ) : (
                <></>
              )}
              {!isValidAge ? (
                <div className="error-message">
                  {options[language].yearLimits}
                </div>
              ) : (
                <></>
              )}
              <div className="gender-inputs">
                <label htmlFor="gender">{options[language].gender}</label>
                <div className="radio-btns">
                  <div className="form-radio-group">
                    <input
                      type="radio"
                      name="size"
                      id="kisi"
                      className="form-radio-input"
                      onChange={(e) => HandleChange(e, 8)}
                    />
                    <label
                      className="form-label-radio form-radio-label"
                      htmlFor="kisi"
                    >
                      <span className="form-radio-button"></span>
                      {options[language].genderMale}
                    </label>
                  </div>
                  <div className="form-radio-group">
                    <input
                      type="radio"
                      name="size"
                      id="qadin"
                      className="form-radio-input"
                      onChange={(e) => HandleChange(e, 9)}
                    />
                    <label
                      htmlFor="qadin"
                      className="form-label-radio form-radio-label"
                    >
                      <span className="form-radio-button"></span> {options[language].genderFemale}
                    </label>
                  </div>
                </div>
              </div>
              {!isValidGender ? (
                <div className="error-message">{options[language].genderReq}</div>
              ) : (
                <></>
              )}
              <div className="register-field phone">
                <label htmlFor="phone">{options[language].mobileNum}</label>
                <PhoneInput
                  className="phone-input"
                  id="phone"
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="AZ"
                  value={phone}
                  onChange={setPhone}
                />
                <span className="country-code">+994</span>
              </div>
              {!isValidNumber ? (
                <div className="error-message">{options[language].correctMobileNum}</div>
              ) : (
                <></>
              )}
              <div className="register-field email">
                <label htmlFor="email">{options[language].email}</label>
                <input
                  placeholder="Email"
                  type="text"
                  id="email"
                  value={email}
                  onChange={(e) => HandleChange(e, 2)}
                />
              </div>
              <div className="register-field email">
                <label htmlFor="taxiCardImg">{options[language].taxiCard}</label>
                <input
                  placeholder="Vöen 2-ci səhifə"
                  type="text"
                  id="taxiCardImg"
                  value={taxiCardImg}
                  onChange={(e) => setTaxiCardImg(e.target.value)}
                />
              </div>
              {!taxiCardImg.length && taxiCardImg === "" ? (
                <div className="error-message">
                  {options[language].taxiCardReq}
                </div>
              ) : (
                <></>
              )}
              <div className="register-field email">
                <label htmlFor="taxiCertificateImg">
                {options[language].taxiCertif}
                </label>
                <input
                  placeholder="Vöen 2-ci səhifə"
                  type="text"
                  id="taxiCertificateImg"
                  value={taxiCertificateImg}
                  onChange={(e) => setTaxiCertificateImg(e.target.value)}
                />
              </div>
              {!taxiCertificateImg.length && taxiCertificateImg === "" ? (
                <div className="error-message">
                  {options[language].taxiCertificateReq}
                </div>
              ) : (
                <></>
              )}
            </div>
            <div
              className="register-buttons"
              style={{
                bottom: "0",
              }}
            >
              <button className="create-acc" type="submit">
                {options[language].howItsWorksSteps[0]}
              </button>
            </div>
          </form>
        </div>
      </Slider>
    </>
  );
}
